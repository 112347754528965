// src/components/ProductCard.js
import React from 'react';
import CustomImage from './CustomImage';
import './ProductCard.css';
import { useNavigate } from 'react-router-dom';
import placeholderImage from '../../assets/product-placeholder.png';

const ProductCard = React.memo(({ product }) => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const imageUrl = product.images && product.images.length > 0 ? `${baseUrl}/productImage/${product.images[0].imageUrl}` : placeholderImage;

  const handleClick = () => {
    // const url = `/product/${product.id}`;
    // window.open(url, '_blank');
    navigate(`/product/${product.id}`);
  };

  return (
    <div
      className="product-card"
      role="article"
      aria-label={`Product: ${product.productName}`}
      onClick={handleClick}
    >
      <CustomImage src={imageUrl} alt={product.productName} className="product-card-image" />
      <div className="product-details">
        <h2 className="product-name">{product.productName}</h2>
        <p className="product-description">{product.productDescription}</p>
        <p className="product-price">Price: ${product.price}</p>
        <p className="product-type">Type: {product.type}</p>
        <p className="product-category">Category: {product.category}</p>
      </div>
    </div>
  );
});

export default ProductCard;
