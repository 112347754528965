// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/HomePage.css */
.homepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.homepage-header,
.homepage-footer {
  background-color: #f8f9fa;
  padding: 1rem;
  text-align: center;
}

.homepage-content {
  flex: 1 1;
  padding: 1rem;
}

.error {
  color: red;
}

.loader-container {
  height: 100vh;
  width: 100vw;
  align-content: center;
}

.loader {
  text-align: center;
}

@media (max-width: 768px) {
  .homepage-content {
    padding: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/HomePage.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;;EAEE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,aAAa;AACf;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["/* src/pages/HomePage.css */\n.homepage {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.homepage-header,\n.homepage-footer {\n  background-color: #f8f9fa;\n  padding: 1rem;\n  text-align: center;\n}\n\n.homepage-content {\n  flex: 1;\n  padding: 1rem;\n}\n\n.error {\n  color: red;\n}\n\n.loader-container {\n  height: 100vh;\n  width: 100vw;\n  align-content: center;\n}\n\n.loader {\n  text-align: center;\n}\n\n@media (max-width: 768px) {\n  .homepage-content {\n    padding: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
