// src/pages/CartPage.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { removeFromCart } from '../actions/cartActions';

const CartContainer = styled.div`
  padding: 20px;
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
`;

const CartPage = () => {
  const cart = useSelector(state => state.cart);
  const dispatch = useDispatch();

  const handleRemove = (productId) => {
    dispatch(removeFromCart(productId));
  };

  return (
    <CartContainer>
      <h1>Your Cart</h1>
      {cart.items.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        cart.items.map(item => (
          <CartItem key={item.id}>
            <div>{item.name}</div>
            <div>${item.price}</div>
            <button onClick={() => handleRemove(item.id)}>Remove</button>
          </CartItem>
        ))
      )}
      <h2>Total: ${cart.total}</h2>
      {/* Proceed to Checkout button */}
    </CartContainer>
  );
};

export default CartPage;
