// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/HubPost.css */
.product-card {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
  margin: 5px 1px;
  padding: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eaeaea;
  transition: opacity 0.3s ease-in-out;
}

.product-image.loading {
  opacity: 0.5;
}

.product-details {
  padding: 16px;
}

.product-name {
  font-size: 1.5em;
  margin: 0;
}

.product-description {
  margin: 8px 0;
}

.product-price,
.product-type,
.product-category {
  margin: 4px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/hub/HubPost.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,wCAAwC;EACxC,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gCAAgC;EAChC,oCAAoC;AACtC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;;;EAGE,aAAa;AACf","sourcesContent":["/* src/components/HubPost.css */\n.product-card {\n  border: 1px solid #eaeaea;\n  border-radius: 8px;\n  overflow: hidden;\n  margin: 5px 1px;\n  padding: 16px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  transition: transform 0.2s;\n}\n\n.product-card:hover {\n  transform: scale(1.05);\n}\n\n.product-image {\n  width: 100%;\n  height: auto;\n  border-bottom: 1px solid #eaeaea;\n  transition: opacity 0.3s ease-in-out;\n}\n\n.product-image.loading {\n  opacity: 0.5;\n}\n\n.product-details {\n  padding: 16px;\n}\n\n.product-name {\n  font-size: 1.5em;\n  margin: 0;\n}\n\n.product-description {\n  margin: 8px 0;\n}\n\n.product-price,\n.product-type,\n.product-category {\n  margin: 4px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
