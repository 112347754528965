// src/components/HubPostList.js
import React, { useEffect, useState } from 'react';
import HubRepository from '../../repositories/HubRepository';
import HubPost from './HubPost';
import '../../App.css';

const HubPostList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchposts = async () => {
      try {
        const data = await HubRepository.getAllPost();
        setPosts(data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchposts();
  }, []);

  return (
    <div className="app">
      <div className="product-grid">
        {posts.map(product => (
          <HubPost key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default HubPostList;
