// src/components/CarouselImage.js
import React, { useState, useEffect } from 'react';
import placeholderImage from '../../assets/product-placeholder.png';

const CarouselImage = ({ src, alt }) => {
  const [imageSrc, setImageSrc] = useState(placeholderImage);
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.onload = () => {
      setImageSrc(img.src);
      setLoading(false);
    };
    img.onerror = () => {
      setImageError(true);
      setImageSrc(placeholderImage);
      setLoading(false);
    };
  }, [src]);

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={`carousel-image ${loading ? 'loading' : ''} ${imageError ? 'error' : ''}`}
    />
  );
};

export default CarouselImage;