// src/pages/ProductPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProductImageCarousel from '../components/product/ProductImageCarousel';
import './ProductPage.css';
import ProductRepository from '../repositories/ProductRepository';


const ProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const data = await ProductRepository.getById(id);
        setProduct(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product:", error);
        setError('Failed to load product. Please try again later.');
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleAddToCart = () => {
    // Add product to cart logic here
    console.log(`Added ${quantity} of ${product.productName} to cart`);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  if (loading) {
    return <div className="loading-overlay">Loading...</div>
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="product-page">
      <div className="product-page-container">
        <div className="product-page-image">
          <ProductImageCarousel images={product.images} />
        </div>
        <div className="product-page-details">
          <h1 className="product-name">{product.productName}</h1>
          <p className="product-price">${product.price}</p>
          <p className="product-description">{product.productDescription}</p>
          <div className="product-specifications">
            <p>Type: {product.type}</p>
            <p>Category: {product.category}</p>
          </div>
          <div className="add-to-cart-container">
            <input type="number" className="quantity-selector" value={quantity} min="1" onChange={handleQuantityChange} />
            <button className="add-to-cart-button" onClick={handleAddToCart}>Add to Cart</button>
          </div>
        </div>
      </div>
      <div className="product-reviews">
        <h2>Customer Reviews</h2>
        {/* Render customer reviews here */}
      </div>
      <div className="related-products">
        <h2>Related Products</h2>
        {/* Render related products here */}
      </div>
    </div>
  );
};

export default ProductPage;
