// src/components/ProductList.js
import React, { useEffect, useState } from 'react';
import ProductRepository from '../../repositories/ProductRepository';
import ProductCard from './ProductCard';
import '../../App.css';

const ProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // const response = await axios.get(`${baseUrl}/api/products`);
        // setProducts(response.data.products);
        const data = await ProductRepository.getAll();
        setProducts(data.products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="app">
      <div className="product-grid">
        {products.map(product => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
