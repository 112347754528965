// src/repositories/ProductRepository.js
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const ProductRepository = {
  getAll: async () => {
    const formData = new FormData();
    formData.append('action', 'getall');
    const response = await axios.post(`${baseUrl}/api/products`, formData);
    return response.data;
  },

  getById: async (id) => {
    const response = await axios.post(`${baseUrl}/api/products`, null, {
      params: { action: 'get', id }
    });
    return response.data;
  },

  create: async (product) => {
    const formData = new FormData();
    formData.append('action', 'add');
    formData.append('productName', product.productName);
    formData.append('productDescription', product.productDescription);
    formData.append('price', product.price);
    formData.append('type', product.type);
    formData.append('category', product.category);
    if (product.images) {
      product.images.forEach((image, index) => {
        formData.append(`images`, image);
      });
    }
    const response = await axios.post(`${baseUrl}/api/products`, formData);
    return response.data;
  },

  update: async (id, product) => {
    const formData = new FormData();
    formData.append('action', 'update');
    formData.append('id', id);
    formData.append('productName', product.productName);
    formData.append('productDescription', product.productDescription);
    formData.append('price', product.price);
    formData.append('type', product.type);
    formData.append('category', product.category);
    if (product.images) {
      product.images.forEach((image, index) => {
        formData.append(`images`, image);
      });
    }
    const response = await axios.post(`${baseUrl}/api/products`, formData);
    return response.data;
  },

  delete: async (id) => {
    const formData = new FormData();
    formData.append('action', 'delete');
    formData.append('id', id);
    const response = await axios.post(`${baseUrl}/api/products`, formData);
    return response.data;
  },

  deleteAll: async () => {
    const formData = new FormData();
    formData.append('action', 'deleteall');
    const response = await axios.post(`${baseUrl}/api/products`, formData);
    return response.data;
  }
};

export default ProductRepository;
