// src/store.js
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk'; // Correct import
import rootReducer from './reducers';

const store = createStore(rootReducer, applyMiddleware(thunk));

const StoreProvider = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
);

export default StoreProvider;
