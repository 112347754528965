// src/components/HubPost.js
import React from 'react';
import CustomImage from '../product/CustomImage';
import './HubPost.css';

const HubPost = React.memo(({ product }) => {
  const imageUrl = `${product.post}`;

  const handleClick = () => {
    window.open(imageUrl, '_blank');
  };

  return (
    <div
      className="product-card"
      role="article"
      aria-label={`Product: ${product.productName}`}
      onClick={handleClick}
    >
      <CustomImage src={imageUrl} alt={product.productName} className="product-card-image" />
      <div className="product-details">
        <h2 className="product-name">{product.productName}</h2>
        <p className="product-description">{product.productDescription}</p>
        <p className="product-price">Price: ${product.price}</p>
        <p className="product-type">Type: {product.type}</p>
        <p className="product-category">Category: {product.category}</p>
      </div>
    </div>
  );
});

export default HubPost;
