// src/components/ProductImageCarousel.js
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CarouselImage from './CarouselImage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ProductImageCarousel.css';
const imageUrl = process.env.REACT_APP_BASE_URL + "/productImage/";

const ProductImageCarousel = ({ images }) => {
  return (
    <Carousel>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <CarouselImage src={`${imageUrl}${image.imageUrl}`} alt={""} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ProductImageCarousel;