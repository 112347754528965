// src/pages/CheckoutPage.js
import React, { useState } from 'react';
import styled from 'styled-components';

const CheckoutContainer = styled.div`
  padding: 20px;
`;

const CheckoutForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
`;

const CheckoutPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    cardNumber: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission, e.g., send data to server
    console.log('Checkout data:', formData);
  };

  return (
    <CheckoutContainer>
      <h1>Checkout</h1>
      <CheckoutForm onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />
        <Input
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleChange}
        />
        <Input
          type="text"
          name="cardNumber"
          placeholder="Card Number"
          value={formData.cardNumber}
          onChange={handleChange}
        />
        <button type="submit">Submit</button>
      </CheckoutForm>
    </CheckoutContainer>
  );
};

export default CheckoutPage;
