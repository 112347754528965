// src/App.js
import React from 'react';
import Routes from './routes';
import StoreProvider from './store';
import { AuthProvider } from './context/AuthContext';
import './App.css';

const App = () => (
  <StoreProvider>
    <AuthProvider>
      <Routes />
    </AuthProvider>
  </StoreProvider>
);

export default App;
