// src/components/Navbar.js
import React, { useState } from 'react';
import './Navbar.css';
import { FaUser, FaShoppingCart } from 'react-icons/fa';

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleLogoClick = () => {
    // Navigate to the homepage
    window.location.href = '/';
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Implement search logic here
    console.log('Searching for:', searchTerm);
    // Redirect to search results page or filter products
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo" onClick={handleLogoClick}>
        <div  className="logo-image" />
      </div>
      <form className="navbar-search" onSubmit={handleSearchSubmit}>
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button type="submit">Search</button>
      </form>
      <div className="navbar-icons">
        <FaUser className="navbar-icon" />
        <FaShoppingCart className="navbar-icon" />
      </div>
    </nav>
  );
};

export default Navbar;
