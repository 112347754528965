// src/routes.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductPage from './pages/ProductPage';
import CartPage from './pages/CartPage';
import CheckoutPage from './pages/CheckoutPage';
import Navbar from './components/common/Navbar';
import HubPostList from './components/hub/HubPostList';
import HubProfileList from './components/hub/HubProfileList';

const AppRoutes = () => (
  <Router>
    <Navbar />
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/product/:id" element={<ProductPage />} />
      <Route exact path="/cart" element={<CartPage />} />
      <Route exact path="/checkout" element={<CheckoutPage />} />
      <Route exact path="/hub/post" element={<HubPostList />} />
      <Route exact path="/hub/profile" element={<HubProfileList />} />
    </Routes>
  </Router>
);

export default AppRoutes;

