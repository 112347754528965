// src/repositories/HubRepository.js
import axios from 'axios';

const baseUrl = process.env.REACT_APP_HUB_URL;

const HubRepository = {
  getAllPost: async () => {
    const response = await axios.get(`${baseUrl}/api/user_post.php`);
    return response.data;
  },

};

export default HubRepository;