// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ProductImageCarousel.css */
.carousel-image {
    width: 500px;
    height: 700px;
    object-fit: cover;
  }`, "",{"version":3,"sources":["webpack://./src/components/product/ProductImageCarousel.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;EACnB","sourcesContent":["/* src/components/ProductImageCarousel.css */\n.carousel-image {\n    width: 500px;\n    height: 700px;\n    object-fit: cover;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
